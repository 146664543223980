import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "breadcrumb-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createBlock(_component_el_breadcrumb, { "separator-class": "el-icon-arrow-right" }, {
    default: _withCtx(() => [
      _createVNode(_TransitionGroup, { name: "breadcrumb" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item) => {
            return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: item.path
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(item.meta?.title), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}