
import StyleExample from "./components/StyleExample.vue";
import store from "../store";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { usePrimaryColor } from "../hooks";
import { primaryColors } from "../../setting";
export default defineComponent({
  name: "Setting",
  components: {
    StyleExample,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { expose }) {
    const opened = ref(props.show);
    const styleExampleList = reactive([
      {
        leftBg: "#000000",
        rightTopBg: "#ffffff",
        rightBottomBg: "#f5f5f5",
        checked: true,
        themeId: "dark-side",
      },
      {
        leftBg: "#ffffff",
        rightTopBg: "#ffffff",
        rightBottomBg: "#d4d4d4",
        checked: false,
        themeId: "light",
      },
    ]);
    const layoutExampleList = reactive([
      {
        leftBg: "#000000",
        rightTopBg: "#d4d4d4",
        rightBottomBg: "#d4d4d4",
        checked: true,
        layoutId: "ltr",
        tipText: "左右",
      },
      {
        leftBg: "#d4d4d4",
        rightTopBg: "#ffffff",
        rightBottomBg: "#d4d4d4",
        checked: false,
        layoutId: "ttb",
        class: "extra-class",
        tipText: "上下",
      },
      {
        leftBg: "#000000",
        rightTopBg: "#d4d4d4",
        rightBottomBg: "#d4d4d4",
        checked: false,
        layoutId: "lcr",
        class: "extra-class-1",
        tipText: "分栏",
      },
    ]);
    const primartyColorList = reactive(
      primaryColors.map((it) => {
        return {
          name: it,
          value: it,
          checked: false,
        };
      })
    );
    const state = store.state;
    onMounted(() => {
      styleExampleList.forEach((it) => {
        it.checked = state.theme === it.themeId;
      });
      layoutExampleList.forEach((it) => {
        it.checked = state.layoutMode === it.layoutId;
      });
      primartyColorList.forEach((it) => {
        it.checked = state.primaryColor === it.value;
      });
    });
    function openDrawer() {
      opened.value = !opened.value;
    }
    function exampleClick(item: any) {
      styleExampleList.forEach((it) => {
        it.checked = it === item;
      });
      store.changeTheme(item.themeId);
      store.saveSetting({ theme: item.themeId });
    }
    function layoutExampleClick(item: any) {
      layoutExampleList.forEach((it) => {
        it.checked = it === item;
      });
      store.changeLayoutMode(item.layoutId);
      store.saveSetting({ layoutMode: item.layoutId });
    }
    function colorClick(item: any) {
      primartyColorList.forEach((it) => {
        it.checked = it === item;
      });
      usePrimaryColor(item.value);
      store.saveSetting({ primaryColor: item.value });
    }
    expose({
      openDrawer,
    });
    return {
      opened,
      styleExampleList,
      layoutExampleList,
      primartyColorList,
      state,
      exampleClick,
      layoutExampleClick,
      colorClick,
    };
  },
});
