import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (_openBlock(), _createBlock(_component_el_sub_menu, {
    index: _ctx.generatorPath(_ctx.item.path),
    "popper-append-to-body": ""
  }, {
    title: _withCtx(() => [
      _createVNode(_component_el_icon, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.meta ? _ctx.item.meta.icon || _ctx.MenuIcon : _ctx.MenuIcon)))
        ]),
        _: 1 /* STABLE */
      }),
      _createElementVNode("span", null, _toDisplayString(_ctx.item.meta ? _ctx.item.meta.title : _ctx.item.name), 1 /* TEXT */)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["index"]))
}