// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-3-1!../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-3-2!normalize.css/normalize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-3-1!../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-3-2!./transition.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "html,\nbody,\n#app {\n  height: 100vh;\n}\n* {\n  box-sizing: border-box;\n}\n\n.el-button--primary{\n  --el-button-background-color: var(--el-color-primary) !important;\n  --el-button-border-color: var(--el-color-primary) !important;\n  --el-button-hover-color: var(--el-color-primary-light-2) !important;\n  --el-button-active-background-color: var(--el-color-primary) !important;\n  --el-button-active-border-color: var(--el-color-primary) !important;\n}\n\n.el-checkbox{\n  height: 30px !important;\n}", ""]);
// Exports
module.exports = exports;
