
import { defineComponent, onMounted, watch } from "vue";
import Quill from "quill";
import "quill/dist/quill.snow.css";
export default defineComponent({
  name: "RichTextEditor",
  props: {
    height: {
      type: [Number, String],
      default: "auto",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let quill: Quill | null = null;
    const init = () => {
      const options = {
        readOnly: props.readonly,
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 切换按钮
            ["blockquote", "code-block"],

            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 减少缩进/缩进
            [{ direction: "rtl" }], // 文本下划线

            [{ size: ["small", false, "large", "huge"] }], // 用户自定义下拉
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // 主题默认下拉，使用主题提供的值
            [{ align: [] }],

            ["link", "image"],

            ["clean"], // 清除格式
          ],
        },
        placeholder: "请输入文章内容…",
        theme: "snow",
      };
      quill = new Quill(
        document.getElementById("richTextEditor") as Element,
        options
      );
    };
    onMounted(init);
    watch(() => props.readonly, (newVal) => {
      if (quill) {
        quill.enable(!newVal);
      }
    });
    const getHtmlContent = () => {
      return (document.getElementById("richTextEditor")?.firstChild as any)
        .innerHTML;
    };
    const getJsonContent = () => {
      return JSON.stringify(quill?.getContents());
    };
    const setContent = (content: string) => {
      if (quill) {
        quill.clipboard.dangerouslyPasteHTML(content);
        quill.setSelection({ index: quill.getLength(), length: 0 }); // 将光标设置到文本的末尾
      }
    };
    const clearContent = () => {
      if (quill) {
        quill.setText('');
      }
    };
    return {
      getHtmlContent,
      getJsonContent,
      setContent,
      clearContent
    };
  },
});
