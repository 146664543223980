import LayoutStore from "@/layouts";
import Cookies from "js-cookie";

import Avatar from "@/assets/img_avatar.gif";
import { defineStore } from "pinia";
import { UserState } from "../types";
import {
  ROLE_ID_KEY,
  USER_ID_KEY,
  USER_INFO_KEY,
  USER_TOKEN_KEY,
} from "../keys";

const defaultAvatar = Avatar;

const userInfo: UserState = JSON.parse(
  localStorage.getItem(USER_INFO_KEY) || "{}"
);

const useUserStore = defineStore("user", {
  state: () => {
    return {
     Token: userInfo.Token || "",
      ID: userInfo.User ? userInfo.User.ID : 0,
      RuleId: userInfo.User ? userInfo.User.RuleId : null,
      Name: userInfo.User ? userInfo.User.Name : "",
      avatar: defaultAvatar,
      Telephone: userInfo.User ? userInfo.User.Telephone : "",
      nickName: userInfo.User ? userInfo.User.Name : "",
    };
  },
  getters: {
    getUserId(): number {
      return this.ID;
    },
    getRroleId(): number | null{
      return this.RuleId;
    },
  },
  actions: {
    saveUser(userInfo: UserState,autoLogin: boolean) {
      return new Promise<void>((res) => {
        this.ID = userInfo.User.ID;
        this.RuleId = userInfo.User.RuleId;
        this.Token = userInfo.Token;
        this.Name = userInfo.User.Name;
        this.nickName = userInfo.User.Name;
        this.Telephone = userInfo.User.Telephone;
        if(autoLogin){
          const inSevenDays = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000)
          Cookies.set(USER_TOKEN_KEY, userInfo.Token, { expires: inSevenDays });
        }else{
           Cookies.set(USER_TOKEN_KEY, userInfo.Token);
        }
        localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));
        res();
      });
    },
    changeNickName(newNickName: string) {
      this.nickName = newNickName;
    },
    logout() {
      return new Promise<void>((res) => {
        localStorage.clear();
        LayoutStore.reset();
        Cookies.remove(USER_TOKEN_KEY);
        res();
      });
    },
  },
});

export default useUserStore;


