
import store from "../store";
import { computed, defineComponent, ref, watch } from "vue";
import { RouteRecordRaw, useRouter } from "vue-router";
export default defineComponent({
  name: "VAWHeader",
  setup() {
    const router = useRouter();
    const state = store.state;
    const routes = computed(() => {
      return store.getTopLevelItems();
    });
    const bgColor = ref({
      backgroundColor:
        state.theme === "light" ? "var(--el-color-white)" : "#001428",
      color: state.theme === "light" ? "inherit" : "var(--el-color-white)",
    });
    watch(
      () => state.theme,
      (newVal) => {
        bgColor.value = {
          backgroundColor:
            state.theme === "light" ? "var(--el-color-white)" : "#001428",
          color: state.theme === "light" ? "inherit" : "var(--el-color-white)",
        };
      }
    );
    function handlePath(routes: RouteRecordRaw[]) {
      for (let index = 0; index < routes.length; index++) {
        const it = routes[index];
        if (it.children && it.children.length > 0) {
          handlePath(it.children);
        } else {
          router.push(it.path);
        }
        break;
      }
    }
    function topItemClick(item: any) {
      handlePath(item.items);
    }
    return {
      bgColor,
      routes,
      state,
      topItemClick,
    };
  },
});
