import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-217c504d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "logo-wrapper",
    style: _normalizeStyle({
    'border-bottom':
      _ctx.state.layoutMode === 'ttb'
        ? 'none'
        : '',
  })
  }, [
    (_ctx.showLogo)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "logo-img",
          src: require('@/assets/logo.png')
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    (_ctx.showTitle)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 1,
          name: "el-fade-in-linear",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("span", { class: "logo-title" }, _toDisplayString(_ctx.setting.projectName), 513 /* TEXT, NEED_PATCH */), [
              [_vShow, !_ctx.state.isCollapse || _ctx.alwaysShow]
            ])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}