
import { isExternal } from "../../utils";
import path from "path-browserify";
import store from "../../store";
import { defineComponent } from "vue";
import { Operation as MenuIcon } from "@element-plus/icons";
export default defineComponent({
  name: "SubMenuItem",
  components: { MenuIcon },
  props: {
    fullPath: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    function generatorPath(childPath: string) {
      if (isExternal(props.fullPath)) {
        return props.fullPath;
      }
      if (isExternal(childPath)) {
        return childPath;
      }
      return path.resolve(props.fullPath, props.item.path);
    }
    return {
      state: store.state,
      generatorPath,
      MenuIcon,
    };
  },
});
