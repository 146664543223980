import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBarItem = _resolveComponent("SideBarItem", true)!

  return (!_ctx.item.hidden)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.sideBarComponent), {
        key: 0,
        "full-path": _ctx.fullPath,
        item: _ctx.item,
        "show-route": _ctx.showRoute,
        onTopItemClick: _ctx.topItemClick
      }, {
        default: _withCtx(() => [
          (_ctx.item.children && _ctx.item.children.length !== 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.item.children, (child) => {
                return (_openBlock(), _createBlock(_component_SideBarItem, {
                  key: child.path,
                  "full-path": _ctx.generatorPath(child.path),
                  item: child
                }, null, 8 /* PROPS */, ["full-path", "item"]))
              }), 128 /* KEYED_FRAGMENT */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 40 /* PROPS, NEED_HYDRATION */, ["full-path", "item", "show-route", "onTopItemClick"]))
    : _createCommentVNode("v-if", true)
}