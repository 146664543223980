import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-355ffca4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.svgClass),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", { href: _ctx.iconName }, null, 8 /* PROPS */, _hoisted_1)
  ], 2 /* CLASS */))
}