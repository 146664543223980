export const login = "/login";
// 获取菜单
export const getMenuListByRoleId = "/roles/menus";
// 角色管理
export const getRoleList = "/roles/";
// 管理员管理
export const getManagers = "/managers/";
// 分类管理
export const articleList = "/articles/types/";
// 文章管理
export const article = "/articles/";
// 价格管理
export const priceList='/subscription_plans/'
// 客户管理
export const membersList='/members/'
// 版本管理
export const versionList='/versions/'
// 阿里云sts授权
export const stsUrl = "/aliyun/sts";
// 获取临时访问权限
export const getImg = "/aliyun/oss_file_permissions";
// 物料设置
export const materials = "/materials/";
// 订单列表
export const orders = "/orders/";
// 获取开票状态
export const invoiceStatus = "/orders/labels/invoice_status";
// 获取订单状态
export const orderStatus = "/orders/labels/status";
// 授权时间
export const authTime = "/orders/months";
// 获取订阅列表
export const userSubscriptions = "/user_subscriptions/";
// 获取使用列表
export const userData = "/user_subscriptions/trials/";
// 更改密码
export const changePassword = "/mine/password";

