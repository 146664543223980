 export const RoutesList = [
   {
     menuUrl: "/article",
     menuName: "文章管理",
     icon: "Message",
     tip: "new",
     parentPath: '',
     key: "/article",
     children: [
       {
         parentPath: '/articleList',
         menuUrl: "/article/articleList",
         menuName: "文章列表",
         key: "/article/articleList"
       },
       {
         parentPath: '/classList',
         menuUrl: "/article/classList",
         menuName: "文章分类",
         key: "/article/classList"
       }
     ],
   },
   {
     menuUrl: "/price",
     menuName: "价格管理",
     icon: "FolderOpened",
     tip: "new",
     key: "/price",
     parentPath: '',
     children: [
       {
         parentPath: '/price',
         menuUrl: "/price/priceList",
         menuName: "价格列表",
         key: "/price/priceList"
       },
     ],
   },
   {
     menuUrl: "/custom",
     menuName: "客户管理",
     icon: "User",
     key: "/custom",
     tip: "new",
     parentPath: '',
     children: [
       {
         parentPath: '/userList',
         menuUrl: "/custom/userList",   
         key: "/custom/userList",
         menuName: "客户列表",
       },
       {
         parentPath: '/orderList',
         menuUrl: "/custom/orderList",
         key: "/custom/orderList",
         menuName: "订单管理",
       }
     ],
   },
   {
     menuUrl: "/versioning",
     menuName: "版本管理",
     icon: "Files",
     tip: "new",
     key: "/versioning",
     parentPath: '',
     children: [
       {
         parentPath: '/versioning',
         menuUrl: "/versioning/verList",
          key: "/versioning/verList",
         menuName: "版本列表",
       },
 
     ],
   },
   {
     menuUrl: "/material",
     menuName: "物料管理",
     icon: "Coin",
     tip: "new",
     key: "/material",
     parentPath: '',
     children: [
       {
         parentPath: '/material',
         menuUrl: "/material/index",
         key: "/material/index",
         menuName: "物料管理",
       },
     ],
   },
   {
     menuUrl: "/system",
     menuName: "系统管理",
     icon: "SettingIcon",
     key: "/system",
     tip: "new",
     parentPath: '',
     children: [
       {
         parentPath: '/system',
         menuUrl: "/system/role",
         menuName: "角色管理",
         key: "/system/role",
       },
        {
         parentPath: '/admin',
         menuUrl: "/system/admin",
         menuName: "管理员管理",
         key: "/system/admin",
       },
     ],
   }];