
import store from "../store";
import { defineComponent, ref, watch, nextTick } from "vue";
import { RouteRecordRaw, useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "InnerSideBar",
  setup() {
    const state = store.state;
    const route = useRoute();
    const router = useRouter();
    const routes = ref<RouteRecordRaw[]>([]);
    watch(
      () => route.fullPath,
      () => {
        nextTick(() => {
          const path = route.matched[0].path;
          const tempRoutes = store.state.permissionRoutes.find(
            (it) => it.path === path
          );
          routes.value = tempRoutes?.children || [];
        });
      },
      {
        immediate: true,
      }
    );
    return {
      state,
      routes,
    };
  },
});
