import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormRender = _resolveComponent("FormRender")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_time_picker = _resolveComponent("el-time-picker")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    class: "base-form-container",
    ref: "form",
    "label-position": _ctx.config.labelPosition || 'right',
    "label-width": (_ctx.config.labelWidth || 80) + 'px',
    size: _ctx.config.size || 'default'
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "prefix", {}, undefined, true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerFormItems, (item, i) => {
        return (_openBlock(), _createBlock(_component_el_form_item, {
          key: i,
          label: item.label,
          class: _normalizeClass({ 'form-item__require': item.validator || item.required })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { style: {"width":"100%"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  span: item.span || 24
                }, {
                  default: _withCtx(() => [
                    (item.render)
                      ? (_openBlock(), _createBlock(_component_FormRender, {
                          key: 0,
                          render: item.render,
                          "form-item": item
                        }, null, 8 /* PROPS */, ["render", "form-item"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (item.type === 'input' && !item.hidden)
                            ? (_openBlock(), _createBlock(_component_el_input, {
                                key: 0,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                placeholder: item.placeholder || '请输入内容',
                                size: _ctx.config.size || 'default',
                                clearable: "",
                                disabled: item.disabled || false,
                                type: item.inputType || '',
                                maxlength: item.maxLength,
                                rows: item.rows || 5,
                                class: "form-item"
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder", "size", "disabled", "type", "maxlength", "rows"]))
                            : (item.type === 'select' && !item.hidden)
                              ? (_openBlock(), _createBlock(_component_el_select, {
                                  key: 1,
                                  modelValue: item.value,
                                  "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                  placeholder: item.placeholder || '请选择条目',
                                  size: _ctx.config.size || 'default',
                                  filterable: item.filterable ? true : false,
                                  clearable: "",
                                  disabled: item.disabled || false,
                                  style: {"width":"100%"},
                                  class: "form-item",
                                  onChange: ($event: any) => (
                item.onChange
                  ? item.onChange(item.value, item.associatedOption || '')
                  : () => {}
              )
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.selectOptions, (optionItem) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: optionItem.value,
                                        value: optionItem.value,
                                        label: optionItem.label
                                      }, null, 8 /* PROPS */, ["value", "label"]))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "placeholder", "size", "filterable", "disabled", "onChange"]))
                              : (item.type === 'date-range' && !item.hidden)
                                ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                    key: 2,
                                    modelValue: item.value,
                                    "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                    type: "daterange",
                                    "range-separator": "-",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    class: "form-item",
                                    disabled: item.disabled || false,
                                    style: {"width":"100%"},
                                    size: _ctx.config.size || 'default'
                                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "size"]))
                                : (item.type === 'date' && !item.hidden)
                                  ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                      key: 3,
                                      modelValue: item.value,
                                      "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                      type: "date",
                                      disabled: item.disabled || false,
                                      "range-separator": "-",
                                      placeholder: item.placeholder || '请选择日期',
                                      class: "form-item",
                                      size: _ctx.config.size || 'default'
                                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder", "size"]))
                                  : (item.type === 'datetime' && !item.hidden)
                                    ? (_openBlock(), _createBlock(_component_el_date_picker, {
                                        key: 4,
                                        modelValue: item.value,
                                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                        type: "datetime",
                                        disabled: item.disabled || false,
                                        placeholder: item.placeholder || '请选择日期',
                                        class: "form-item",
                                        size: _ctx.config.size || 'default'
                                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder", "size"]))
                                    : (item.type === 'time' && !item.hidden)
                                      ? (_openBlock(), _createBlock(_component_el_time_picker, {
                                          key: 5,
                                          modelValue: item.value,
                                          "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                          "arrow-control": "",
                                          "picker-options": {
                selectableRange: '00:00:00 - 23:59:59',
              },
                                          disabled: item.disabled || false,
                                          placeholder: item.placeholder || '请选择时间',
                                          class: "form-item",
                                          size: _ctx.config.size || 'default'
                                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder", "size"]))
                                      : _createCommentVNode("v-if", true),
                          (item.type === 'radio-group' && !item.hidden)
                            ? (_openBlock(), _createBlock(_component_el_radio_group, {
                                key: 6,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                disabled: item.disabled || false,
                                size: _ctx.config.size || 'default',
                                onChange: ($event: any) => (
                item.onChange
                  ? item.onChange(item.value, item.associatedOption || '')
                  : () => {}
              )
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.radioOptions, (optionItem) => {
                                    return (_openBlock(), _createBlock(_resolveDynamicComponent(item.style === 'button' ? 'el-radio-button' : 'el-radio'), {
                                      key: optionItem.value,
                                      label: optionItem.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(optionItem.label), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "disabled", "size", "onChange"]))
                            : _createCommentVNode("v-if", true),
                          (item.type === 'check-group' && !item.hidden)
                            ? (_openBlock(), _createBlock(_component_el_checkbox_group, {
                                key: 7,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                disabled: item.disabled || false,
                                size: _ctx.config.size || 'default',
                                onChange: ($event: any) => (
                item.onChange
                  ? item.onChange(item.value, item.associatedOption || '')
                  : () => {}
              )
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.checkOptions, (optionItem) => {
                                    return (_openBlock(), _createBlock(_resolveDynamicComponent(
                  item.style === 'button' ? 'el-checkbox-button' : 'el-checkbox'
                ), {
                                      key: optionItem.value,
                                      label: optionItem.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(optionItem.label), 1 /* TEXT */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]))
                                  }), 128 /* KEYED_FRAGMENT */))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "disabled", "size", "onChange"]))
                            : _createCommentVNode("v-if", true),
                          (item.type === 'switch' && !item.hidden)
                            ? (_openBlock(), _createBlock(_component_el_switch, {
                                key: 8,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                disabled: item.disabled || false,
                                size: _ctx.config.size || 'default',
                                onChange: ($event: any) => (
                item.onChange
                  ? item.onChange(item.value, item.associatedOption || '')
                  : () => {}
              )
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "size", "onChange"]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["span"])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "class"]))
      }), 128 /* KEYED_FRAGMENT */)),
      _renderSlot(_ctx.$slots, "extra", {}, undefined, true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["label-position", "label-width", "size"]))
}