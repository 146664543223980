import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_MainLayout = _resolveComponent("MainLayout")!
  const _component_VAWHeader = _resolveComponent("VAWHeader")!
  const _component_InnerSideBar = _resolveComponent("InnerSideBar")!
  const _component_TabSplitSideBar = _resolveComponent("TabSplitSideBar")!
  const _component_Setting = _resolveComponent("Setting")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vaw-layout-container", [_ctx.state.device === 'mobile' && 'is-mobile', _ctx.state.theme]])
  }, [
    (_ctx.state.device === 'mobile')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_SideBar, { ref: "sideBar" }, null, 512 /* NEED_PATCH */),
          _createVNode(_component_MainLayout)
        ], 64 /* STABLE_FRAGMENT */))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.state.layoutMode === 'ttb')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_VAWHeader),
                _createVNode(_component_InnerSideBar)
              ], 64 /* STABLE_FRAGMENT */))
            : (_ctx.state.layoutMode === 'lcr')
              ? (_openBlock(), _createBlock(_component_TabSplitSideBar, { key: 1 }))
              : (_openBlock(), _createBlock(_component_SideBar, {
                  key: 2,
                  ref: "sideBar"
                }, null, 512 /* NEED_PATCH */)),
          _createVNode(_component_MainLayout, {
            "show-nav-bar": _ctx.state.layoutMode !== 'ttb'
          }, null, 8 /* PROPS */, ["show-nav-bar"])
        ], 64 /* STABLE_FRAGMENT */)),
    (_ctx.state.device === 'mobile')
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["mobile-shadow", [_ctx.state.isCollapse ? 'close-shadow' : 'show-shadow']]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
        }, null, 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_Setting, { ref: "appSettingRef" }, null, 512 /* NEED_PATCH */)
  ], 2 /* CLASS */))
}