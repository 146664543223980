
import screenfull from "screenfull";
import store from "../store";
import { defineComponent, ref } from "vue";
import {
  Refresh,
  FullScreen,
  Setting as SettingIcon,
} from "@element-plus/icons";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import useEmit from "@/hooks/Emit";

export default defineComponent({
  name: "ActionItems",
  components: {
    Refresh,
    FullScreen,
    SettingIcon,
  },
  setup() {
    // const searchContentRef = ref();
    const showSearchContent = ref(false);
    const searchContent = ref("");
    const state = store.state;
    const router = useRouter();
    const route = useRoute();
    const emit = useEmit();

    function onShowSearch() {
      showSearchContent.value = !showSearchContent.value;
      searchContent.value = "";
      // if (showSearchContent.value) {
      //   searchContentRef.value?.focus();
      // } else {
      //   searchContentRef.value?.blur();
      // }
    }
    function onChange(content: string) {
      if (!content) {
        return;
      }
      window.open("https://www.baidu.com/s?wd=" + content);
    }
    function onScreenFull() {
      if (!screenfull.isEnabled) {
        ElMessage.error("当前浏览器不支持全屏操作");
        return false;
      }
      screenfull.toggle();
    }
    function onRefrehRoute() {
      router.replace({ path: "/redirect" + route.path });
    }
    function onShowSetting() {
      emit?.emit("show_setting");
    }
    return {
      // searchContentRef,
      showSearchContent,
      searchContent,
      state,
      onShowSearch,
      onShowSetting,
      onRefrehRoute,
      onScreenFull,
      onChange,
    };
  },
});
