import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    id: "tableBody",
    "body-style": { padding: 0 },
    class: "table-body-container table-container",
    shadow: "never"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true),
      _renderSlot(_ctx.$slots, "tableConfig", {}, undefined, true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    _: 3 /* FORWARDED */
  }))
}