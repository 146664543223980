
import { defineComponent } from 'vue'
import store from '../store'
export default defineComponent({
  name: 'NavBar',
  setup() {
    return {
      state: store.state
    }
  }
})
