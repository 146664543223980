
import { defineComponent, onMounted, shallowReactive, watch } from 'vue'
import { RouteLocationMatched, useRoute } from 'vue-router'
export default defineComponent({
  name: 'Breadcrumb',
  setup() {
    const breadcrumbs = shallowReactive([] as Array<RouteLocationMatched>)
    const route = useRoute()
    function generatorBreadcrumb() {
      breadcrumbs.length = 0
      breadcrumbs.push(
        ...route.matched.filter((it) => {
          return it.meta && it.meta.title
        })
      )
    }
    watch(
      () => route.path,
      () => {
        generatorBreadcrumb()
      }
    )
    onMounted(generatorBreadcrumb)
    return {
      breadcrumbs
    }
  }
})
