
import store from '../store'
import { defineComponent } from 'vue'
import { Fold } from '@element-plus/icons'
export default defineComponent({
  name: 'Humburger',
  components: {Fold},
  setup() {
    function toggleFold() {
      store.toggleCollapse(!store.state.isCollapse)
    }
    return {
      state: store.state,
      toggleFold
    }
  }
})
