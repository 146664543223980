import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(_ctx.mMode === 'vertical'
    ? _ctx.innerMode
      ? 'scrollbar-inner-mode'
      : 'scrollbar'
    : ''
    ),
    "wrap-class": "scrollbar-wrap-class"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_menu, {
        "default-active": _ctx.activePath,
        mode: _ctx.mMode,
        collapse: _ctx.mMode === 'vertical' && _ctx.state.isCollapse,
        "active-text-color": "var(--el-color-primary)",
        "text-color": _ctx.textColor,
        "background-color": _ctx.bgColor,
        "unique-opened": "",
        style: _normalizeStyle({ height: _ctx.mMode === 'vertical' ? '100%' : '48px' })
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["default-active", "mode", "collapse", "text-color", "background-color", "style"])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["class"]))
}