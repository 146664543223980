import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-left":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!

  return (_openBlock(), _createBlock(_component_el_menu_item, {
    index: _ctx.generatorPath(),
    onClick: _ctx.handleClick
  }, {
    title: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.showRoute.meta ? _ctx.showRoute.meta.title : _ctx.showRoute.name), 1 /* TEXT */),
      _createCommentVNode(" <MenuItemTip\n        :value=\"showRoute.meta ? showRoute.meta.badge || '' : ''\"\n        :is-dot=\"showRoute.meta ? showRoute.meta.badge === 'dot' : false\"\n      /> ")
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["index", "onClick"]))
}