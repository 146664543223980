import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fold = _resolveComponent("Fold")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createBlock(_component_el_icon, {
    class: _normalizeClass(["fold-wrapper", [_ctx.state.isCollapse ? 'fold-close-status' : 'fold-open-status']]),
    onClick: _ctx.toggleFold
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Fold)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "onClick"]))
}