
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuItemTip",
  props: {
    isDot: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
});
