
import { defineComponent } from "vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";

export default defineComponent({
  name: "App",
  setup() {
    return {
      zhCn,
    };
  },
});
