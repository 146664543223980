
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'StyleExample',
  props: {
    leftBg: {
      type: String,
      default: '#000'
    },
    rightTopBg: {
      type: String,
      default: '#ff0000'
    },
    rightBottomBg: {
      type: String,
      default: '#f5f5f5'
    },
    checked: {
      type: Boolean,
      default: false
    },
    tipText: {
      type: String,
      default: null
    }
  }
})
