
import { defineComponent, PropType, reactive, ref } from "vue";
import draggable from "vuedraggable";
import { Setting as SettingIcon } from "@element-plus/icons";
export default defineComponent({
  name: "SortableTable",
  components: { draggable, SettingIcon },
  emits: ["update"],
  props: {
    tableProps: {
      type: Array as PropType<TablePropsType[]>,
    },
  },
  setup(props, { emit }) {
    const tempTableProps = props.tableProps as Array<TablePropsType>;
    const innerTableProps = ref(
      tempTableProps.map((it: TablePropsType) => {
        return { ...it } as TablePropsType;
      })
    );
    const originTableProps = tempTableProps.map((it: TablePropsType) => {
      return { ...it } as TablePropsType;
    });
    const isIndeterminate = ref(
      tempTableProps.filter((it: TablePropsType) => it.checked).length !==
      tempTableProps.length
    );
    const allChecked = ref(
      tempTableProps.every((it: TablePropsType) => it.checked)
    );
    const onAllChange = (value: boolean) => {
      innerTableProps.value.forEach(
        (it: TablePropsType) => (it.checked = value)
      );
      emit(
        "update",
        innerTableProps.value.filter((it) => it.checked)
      );
    };
    const onChange = () => {
      const checkedItems = innerTableProps.value.filter(
        (it: TablePropsType) => it.checked
      );
      allChecked.value = checkedItems.length === innerTableProps.value.length;
      isIndeterminate.value =
        checkedItems.length > 0 &&
        checkedItems.length !== innerTableProps.value.length;
      emit("update", checkedItems);
    };
    const onReset = () => {
      innerTableProps.value = originTableProps.map((it: TablePropsType) => {
        return { ...it };
      });
      onChange();
    };
    function onDraggableEnd() {
      emit(
        "update",
        innerTableProps.value.filter((it) => it.checked)
      );
    }
    return {
      innerTableProps,
      originTableProps,
      isIndeterminate,
      allChecked,
      onAllChange,
      onChange,
      onReset,
      onDraggableEnd,
      SettingIcon,
    };
  },
});
