import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66b78131"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "action-items-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_FullScreen = _resolveComponent("FullScreen")!
  const _component_SettingIcon = _resolveComponent("SettingIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.actionItem.showRefresh)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "action-item",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRefrehRoute && _ctx.onRefrehRoute(...args)))
        }, [
          _createVNode(_component_el_icon, { size: 18 }, {
            default: _withCtx(() => [
              _createVNode(_component_Refresh)
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.state.actionItem.showFullScreen && _ctx.state.device !== 'mobile')
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "action-item",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onScreenFull && _ctx.onScreenFull(...args)))
        }, [
          _createVNode(_component_el_icon, { size: 18 }, {
            default: _withCtx(() => [
              _createVNode(_component_FullScreen)
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    (_ctx.state.device !== 'mobile')
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "action-item",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onShowSetting && _ctx.onShowSetting(...args)))
        }, [
          _createVNode(_component_el_icon, { size: 18 }, {
            default: _withCtx(() => [
              _createVNode(_component_SettingIcon)
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}