import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e7ede98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner-humbuger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBarItem = _resolveComponent("SideBarItem")!
  const _component_ScrollerMenu = _resolveComponent("ScrollerMenu")!
  const _component_Humburger = _resolveComponent("Humburger")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vaw-inner-side-bar-wrapper", [!_ctx.state.isCollapse ? 'open-status' : 'close-status']])
  }, [
    _createVNode(_component_ScrollerMenu, { "inner-mode": true }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (item) => {
          return (_openBlock(), _createBlock(_component_SideBarItem, {
            key: item.path,
            "full-path": item.path,
            item: item
          }, null, 8 /* PROPS */, ["full-path", "item"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Humburger)
    ])
  ], 2 /* CLASS */))
}