import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4f1f622"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo-wrapper" }
const _hoisted_2 = { class: "menu-wrapper" }
const _hoisted_3 = { class: "right-wrapper" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "avatar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_SideBarItem = _resolveComponent("SideBarItem")!
  const _component_ScrollerMenu = _resolveComponent("ScrollerMenu")!
  const _component_ActionItems = _resolveComponent("ActionItems")!
  const _component_VAWAvatar = _resolveComponent("VAWAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vaw-header-layout",
    style: _normalizeStyle(_ctx.bgColor)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Logo, { "always-show": true })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ScrollerMenu, { mode: "horizontal" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (item) => {
            return (_openBlock(), _createBlock(_component_SideBarItem, {
              key: item.path,
              "full-path": item.path,
              item: item,
              onTopItemClick: _ctx.topItemClick
            }, null, 8 /* PROPS */, ["full-path", "item", "onTopItemClick"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.state.device !== 'mobile')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_ActionItems)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_VAWAvatar)
      ])
    ])
  ], 4 /* STYLE */))
}