
import { useForm } from "@/hooks";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  PropType,
  watch,
} from "vue";
import { FormRenderItem } from "./FormRender";
export default defineComponent({
  name: "BaseForm",
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          size: "default",
          labelWidth: "80",
          labelPosition: "right",
        };
      },
    },
    formItems: {
      type: Array as PropType<Array<FormRenderItem>>,
      default: () => {
        return [];
      },
    },
  },
  setup(props, { expose }) {
    const { checkParams, resetParams, generatorParams } = useForm();
    const innerFormItems = computed(() => {
      return props.formItems;
    });
    function mCheckParams() {
      return checkParams(innerFormItems.value);
    }
    function mResetParams() {
      return resetParams(innerFormItems.value);
    }
    function mGeneratorParams() {
      return generatorParams(innerFormItems.value);
    }
    expose({
      checkParams: mCheckParams,
      resetParams: mResetParams,
      generatorParams: mGeneratorParams,
    });
    return {
      innerFormItems,
    };
  },
});
