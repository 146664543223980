
import { computed, defineComponent, nextTick, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "../../store";
const __default__ = defineComponent({
  name: "ScrollerMenu",
  props: {
    routes: {
      type: Array,
      require: true,
      default: () => {
        return [];
      },
    },
    fullPath: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: "vertical",
    },
    innerMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = store.state;
    const route = useRoute();
    const bgColor = computed(() => {
      return state.theme === "light"
        ? "var(--el-color-white)"
        : props.innerMode
          ? "var(--el-color-white)"
          : "#001428";
    });
    const textColor = computed(() => {
      return state.theme === "light"
        ? "#303133"
        : props.innerMode
          ? "#303133"
          : "#bbbbbb";
    });
    const activePath = ref(route.fullPath);
    const mMode = computed(() => {
      return props.mode;
    });
    watch(
      () => route.fullPath,
      () => {
        nextTick(() => {
          if (mMode.value === "vertical") {
            activePath.value = route.fullPath;
          } else {
            activePath.value = route.matched[0].path;
          }
        });
      },
      {
        immediate: true,
      }
    );
    return {
      mMode,
      activePath,
      state,
      bgColor,
      textColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "6ccc8ca0-bgColor": (_ctx.bgColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__