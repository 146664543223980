
import store from "../store";
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "SideBar",
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const state = store.state;
    const routes = computed(() => {
      return state.permissionRoutes.filter((it) => !!it.name);
    });
    return {
      state,
      routes,
    };
  },
});
