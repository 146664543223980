
import store from "./store";
import { computed, defineComponent } from "vue";
export default defineComponent({
  name: "MainLayout",
  props: {
    showNavBar: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const state = store.state;
    function onFixedHeader() {
      store.toggleFixedNavBar(!state.isFixedNavBar);
    }
    const mShowNavBar = computed(() => {
      return props.showNavBar;
    });
    const isShowHeader = computed(() => {
      return store.isShowHeader();
    });
    return {
      state,
      mShowNavBar,
      isShowHeader,
      onFixedHeader,
    };
  },
});
