
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import store from "./store";
import { toHump } from "./utils";
export default defineComponent({
  name: "Main",
  setup() {
    const state = store.state;
    const route = useRoute();
    const routeKey = ref(route.fullPath);
    const cachedViews = computed(() => {
      return state.cachedView.map((it) => it);
    });
    watch(
      () => route.fullPath,
      () => {
        if (route.name && route.meta && route.meta.cacheable) {
          const humName = toHump(route.name as string);
          if (!store.state.cachedView.includes(humName)) {
            store.state.cachedView.push(humName);
          }
        }
        routeKey.value = route.fullPath;
      },
      {
        immediate: true
      }
    );
    return {
      state,
      cachedViews,
      routeKey,
    };
  },
});
