
import store from "../store";
import { defineComponent, ref, watch } from "vue";
import { useSetting } from "@/hooks";
const __default__ = defineComponent({
  name: "Logo",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    alwaysShow: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const setting = useSetting();
    const state = store.state;
    const bgColor = ref(
      state.theme === "light" ? "var(--el-color-white)" : "#001428"
    );
    const textColor = ref(
      state.theme === "light" ? "#001428" : "var(--el-color-white)"
    );
    watch(
      () => state.theme,
      (newVal) => {
        bgColor.value =
          newVal === "light" ? "var(--el-color-white)" : "#001428";
        textColor.value =
          state.theme === "light" ? "#001428" : "var(--el-color-white)";
      }
    );
    return {
      state,
      setting,
      bgColor,
      textColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "217c504d-bgColor": (_ctx.bgColor),
  "217c504d-textColor": (_ctx.textColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__