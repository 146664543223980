import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d2b4864"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right" }
const _hoisted_2 = {
  key: 0,
  style: {"font-size":"12px","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["style-example-container", { 'circle-tip': _ctx.checked }])
  }, [
    _createElementVNode("div", {
      class: "left",
      style: _normalizeStyle({ 'background-color': _ctx.leftBg })
    }, null, 4 /* STYLE */),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "right-top",
        style: _normalizeStyle({ 'background-color': _ctx.rightTopBg })
      }, null, 4 /* STYLE */),
      _createElementVNode("div", {
        class: "right-bottom",
        style: _normalizeStyle({ 'background-color': _ctx.rightBottomBg })
      }, null, 4 /* STYLE */)
    ]),
    (_ctx.tipText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.tipText), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}