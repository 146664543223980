
import { useEmit } from "@/hooks";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import store from "./store";
export default defineComponent({
  name: "Layout",
  setup() {
    const emit = useEmit();
    const appSettingRef = ref();
    emit?.on("show_setting", () => {
      appSettingRef.value.openDrawer();
    });
    function handleScreenResize() {
      const width = document.body.clientWidth;
      if (width <= 768) {
        store.changeDevice("mobile");
        store.toggleCollapse(true);
      } else if (width < 992 && width > 768) {
        store.changeDevice("pad");
        store.toggleCollapse(true);
      } else if (width < 1200 && width >= 992) {
        store.changeDevice("pc");
        store.toggleCollapse(false);
      } else {
        store.changeDevice("pc");
        store.toggleCollapse(false);
      }
    }
    function closeMenu() {
      store.toggleCollapse(true);
    }
    onMounted(() => {
      handleScreenResize();
      window.addEventListener("resize", handleScreenResize);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleScreenResize);
    });
    return {
      appSettingRef,
      state: store.state,
      closeMenu,
    };
  },
});
