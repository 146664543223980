
import { useSetting } from "@/hooks";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Footer",
  setup() {
    return {
      setting: useSetting(),
    };
  },
});
