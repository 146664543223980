
import { isExternal } from "../../utils";
import path from "path-browserify";
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Operation as OperationIcon } from "@element-plus/icons";
export default defineComponent({
  name: "MenuItem",
  props: {
    fullPath: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showRoute: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["top-item-click"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    function generatorPath() {
      if (isExternal(props.fullPath)) {
        return props.fullPath;
      }
      if (isExternal(props.showRoute.path)) {
        return props.showRoute.path;
      }
      return path.resolve(props.fullPath, props.showRoute.path);
    }
    function handleClick() {
      if (props.showRoute.isTopItem) {
        emit("top-item-click", props.showRoute);
        return;
      }
      if (isExternal(props.showRoute.path)) {
        window.open(props.showRoute.path);
      } else if (isExternal(props.fullPath)) {
        window.open(props.fullPath);
      } else if (
        route.path !== path.resolve(props.fullPath, props.showRoute.path)
      ) {
        router.push({
          path: path.resolve(props.fullPath, props.showRoute.path),
        });
      }
    }
    return {
      generatorPath,
      handleClick,
      OperationIcon,
    };
  },
});
